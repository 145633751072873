import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-man-van',
  templateUrl: './man-van.component.html',
  styleUrls: ['./man-van.component.scss']
})
export class ManVanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

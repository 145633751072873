<!-- Inner Banner -->
<div class="inner-banner inner-bg3">
  <div class="container">
      <div class="inner-title text-center">
          <h3>National Moves</h3>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>National Moves</li>
          </ul>
      </div>
  </div>
</div>
<!-- Inner Banner End -->


<!-- Services Details -->
<div class="services-details pt-100 pb-70">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-12">
              <div class="services-details-content">
                  <img src="assets/img/services/services-details-7.jpg" alt="Images">

                  <div class="row">
                      <div class="col-lg-12 col-md-12">
                          <div class="services-details-card">
                              <h3>National Moves</h3>
                              <p>We have founded our removals company on the principles of giving customers a friendly, reliable, helpful, and professional moving service, tailored to your individual needs. We use our jumbo Luton van that includes satellite navigation and is fully equipped with everything else we need for the moves such as blankets, straps, sack barrow, power tools. We are always dressed in full uniform, for professional appearance and safety.</p>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We are based from Aberdeen & Aberdeenshire and can move you to anywhere, or from anywhere. With a small business you are more than a number, and we are more than just employees. We want your move to be the best it can be, so we go above and beyond to make that happen.  </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-12">
              <app-services-category></app-services-category>
          </div>
      </div>
  </div>
</div>
<!-- Services Details End -->



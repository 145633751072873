<!-- Inner Banner -->
<div class="inner-banner inner-bg3">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Dismantle</h3>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>Dismantle</li>
          </ul>
      </div>
  </div>
</div>
<!-- Inner Banner End -->


<!-- Services Details -->
<div class="services-details pt-100 pb-70">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-12">
              <div class="services-details-content">
                  <img src="assets/img/services/services-details-4.jpg" alt="Images">

                  <div class="row">
                      <div class="col-lg-12 col-md-12">
                          <div class="services-details-card">
                              <h3>Dismantle</h3>
                              <p>Dismantle / Re-Assemble provide dismantle and re-assemble services for all Home Moves at competitive prices. Here at Ready Steady Movers, our team of professional removals experts are experienced in moving bulky and cumbersome furniture. Prior to moving day, your personal removal evaluator will pay you a visit to discuss your needs and assess any items of furniture that may need special attention. He will then discuss the options that are open to you, such as dismantling or removing doors from hinges, and provide a detailed report of your situation to the removals team leader.</p>
                              <h4>What do we Dismantle?</h4>
                              <p>All types of beds, bunk beds, specialist beds, all types of wardrobes, glass, sliding door, custom made units, all table legs, display units, sofa legs, disconnect washing machines, fridges, dishwashers, tumble dryers but we do not re-connect these appliances. Dismantle garden furniture, swings, playhouses, play areas but we do not dismantle sheds. </p>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Some furniture can be dismantled and moved in parts, while other furniture, such as sofas, may need a bit more planning and preparation in order to be moved. This requires careful planning and forward thinking, but when it comes to helping you move house, no problem is too big or too small for the Ready Steady Movers team.  </p>
                              <h4>Tools of the Trade</h4>
                              <p>We always keep a well-stocked selection of tools as we use these every day in our house moves and removals. We help people with every aspect of their moves and this usually includes dismantling and/or building their furniture if needed. So we are used to quickly, carefully and reliably dismantling or building furniture for our customers.</p>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our fully stocked toolbox includes all the tools we need on a daily basis – drills, screwdrivers, spanners, hammers and so on. Everything we need for the job. So let us help, we can do the hard work, while you get on with what you need to do. We can make your project a dream come true. </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-12">
              <app-services-category></app-services-category>
          </div>
      </div>
  </div>
</div>
<!-- Services Details End -->



<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bxs-phone'></i>
                    <div class="content">
                        <h3>Landline Number</h3>
                        <p><a href="tel:+44 1224 483098"> +44 1224 483098</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bxs-mobile'></i>
                    <div class="content">
                        <h3>Mobile Number</h3>
                        <p> <a href="tel:+44 7869 680196"> +44 7869 680196</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4 offset-lg-0 offset-md-0 offset-sm-3">
                <div class="contact-card">
                    <i class='bx bx-envelope'></i>
                    <div class="content">
                        <h3>Email</h3>
                        <p><a href="mailto:sales@readysteadymovers.co.uk">sales@readysteadymovers.co.uk</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Top Removalists Company in Aberdeen & Aberdeenshire</h2>
                <p>When you book with Ready Steady Movers, you’ve got one less task to worry about. That’s why our customers keep coming back year after year.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contact Us</h2>
                        </div>

                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group" [ngClass]="displayFieldCss('name')">
                                        <input type="text" name="name" id="name" class="form-control" formControlName="name" required placeholder="Name">
                                        <app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Name is required"></app-field-error-display>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group" [ngClass]="displayFieldCss('email')">
                                        <input type="email" name="email" id="email" class="form-control" formControlName="email" required placeholder="Email">
                                        <app-field-error-display [displayError]="isFieldValid('email')" errorMsg="Email is required"></app-field-error-display>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group" [ngClass]="displayFieldCss('phone_number')">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" formControlName="phone_number" placeholder="Phone">
                                        <app-field-error-display [displayError]="isFieldValid('phone_number')" errorMsg="Phone is required"></app-field-error-display>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group" [ngClass]="displayFieldCss('subject')">
                                        <input type="text" name="subject" id="subject" class="form-control" formControlName="subject" required placeholder="Your Subject">
                                        <app-field-error-display [displayError]="isFieldValid('subject')" errorMsg="Subject is required"></app-field-error-display>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group" [ngClass]="displayFieldCss('message')">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" formControlName="message" required placeholder="Your Message"></textarea>
                                        <app-field-error-display [displayError]="isFieldValid('message')" errorMsg="Message is required"></app-field-error-display>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d552937.8138625043!2d-3.343396739275355!3d57.223983240694366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48837fd867aed7d5%3A0x5035fdf449cde207!2sAberdeenshire%2C%20UK!5e0!3m2!1sen!2s!4v1625422735871!5m2!1sen!2s" allowfullscreen="" loading="lazy"></iframe>
</div>
<!-- Map Area End -->

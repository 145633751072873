<!-- Inner Banner -->
<div class="inner-banner inner-bg3">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Moving Home</h3>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>Moving Home</li>
          </ul>
      </div>
  </div>
</div>
<!-- Inner Banner End -->


<!-- Services Details -->
<div class="services-details pt-100 pb-70">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-12">
              <div class="services-details-content">
                  <img src="assets/img/services/services-details-1.jpg" alt="Images">

                  <div class="row">
                      <div class="col-lg-12 col-md-12">
                          <div class="services-details-card">
                              <h3>Moving Home</h3>
                              <p>Moving home should be one of the most exciting times of your life, but all too often the stress of moving makes it a moment that many of us wish to forget; but this doesn’t have to be the case. See our removals team in action by hiring them. We are super efficient when it comes to local moves, you’ll be impressed on how professional, polite, careful and quickly we can complete UK moves due to our experience. </p>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moving home couldn’t be simpler, whether you need a flat removal service or household removals, call us, and relax, don’t break your back, let the professionals take care of it. I know various companies on the market that charge extra for stairs, fuel mileage – We don’t have any hidden costs.</p>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This means no nasty big bills for you to pay at the end, because we were slow on taking breaks for cigarettes and lunch. Having a full belly only slows you down; no one likes to work on a full belly. Just another reason why we are not your average Man and van service. </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-4 col-md-12">
              <app-services-category></app-services-category>
          </div>
      </div>
  </div>
</div>
<!-- Services Details End -->



<!-- Inner Banner -->
<div class="inner-banner inner-bg12">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Quotation</h3>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>Quotation</li>
          </ul>
      </div>
  </div>
</div>
<!-- Inner Banner End -->

<!-- Quotation Area -->
<div class="contact-area pb-70">
    <div class="section-title text-center">
        <h2>Get a Free Quote</h2>
    </div>
    <form [formGroup]="quotationForm" (ngSubmit)="onSubmit()">
        <div class="contact-form">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="section-title-sub">
                        <h5>Your personal details</h5>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-sm-6">
                            <label for="salutation" class="label">Salutation</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <select id="salutation" name="salutation"  formControlName="salutation" class="form-control" placeholder="Salutation">
                                    <option value="">Please Select</option>
                                    <option [ngValue]="c" *ngFor="let c of salutationList">{{c}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="firstName" class="label required-field">First Name</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('firstName')">
                                <input type="text" id="firstName" class="form-control" formControlName="firstName" required>
                                <app-field-error-display [displayError]="isFieldValid('firstName')" errorMsg="First Name is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="firstName" class="label required-field">Surname</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('surname')">
                                <input type="text" id="surname" class="form-control" formControlName="surname" required>
                                <app-field-error-display [displayError]="isFieldValid('surname')" errorMsg="Surname is required"></app-field-error-display>
                            </div>
                        </div> 
                        <div class="col-lg-2 col-sm-6">
                            <label for="firstName" class="label">Company</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="companyName" class="form-control" formControlName="companyName">
                            </div>
                        </div> 
                        <div class="col-lg-2 col-sm-6">
                            <label for="firstName" class="label required-field">Move Date</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('moveDate')">
                                <input type="date" name="moveDate" id="moveDate" class="form-control" formControlName="moveDate" required>
                                <app-field-error-display [displayError]="isFieldValid('moveDate')" errorMsg="Move Date is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="firstName" class="label required-field">Landline</label>
                        </div>
                        <div class="col-lg-4 col-sm-6"> 
                            <div class="form-group" [ngClass]="displayFieldCss('landline')">
                                <input type="text" id="landline" class="form-control" formControlName="landline" required>
                                <app-field-error-display [displayError]="isFieldValid('landline')" errorMsg="Landline is required"></app-field-error-display>
                            </div>
                        </div> 
                        <div class="col-lg-2 col-sm-6">
                            <label for="firstName" class="label">Extension</label>
                        </div>
                        <div class="col-lg-4 col-sm-6"> 
                            <div class="form-group">
                                <input type="text" id="extension" class="form-control" formControlName="extension">
                            </div>
                        </div> 
                        <div class="col-lg-2 col-sm-6">
                            <label for="firstName" class="label required-field">Mobile No</label>
                        </div>
                        <div class="col-lg-4 col-sm-6"> 
                            <div class="form-group" [ngClass]="displayFieldCss('mobileNumber')">
                                <input type="text" id="mobileNumber" class="form-control" formControlName="mobileNumber" required>
                                <app-field-error-display [displayError]="isFieldValid('mobileNumber')" errorMsg="Mobile number is required"></app-field-error-display>
                            </div>
                        </div> 
                        <div class="col-lg-2 col-sm-6">
                            <label for="firstName" class="label required-field">Email</label>
                        </div>
                        <div class="col-lg-10 col-sm-6"> 
                            <div class="form-group" [ngClass]="displayFieldCss('email')">
                                <input type="email" name="email" id="email" class="form-control" formControlName="email" required>
                                <app-field-error-display [displayError]="isFieldValid('email')" errorMsg="Email is required"></app-field-error-display>
                            </div>
                        </div> 
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="section-title-sub">
                        <h5>General details</h5>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <label for="isReassembly" class="label required-field">Reassembly required?</label>
                        </div>
                        <div class="col-lg-8 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('isReassembly')">
                                <div class="row">
                                    <div class="col-lg-2 col-sm-6">
                                        <input type="radio" id="isReassembly1" name="isReassembly" value="Yes" class="" formControlName="isReassembly" required>
                                        <label for="isReassembly1" class="label">Yes</label><br>
                                    </div>
                                    <div class="col-lg-2 col-sm-6">
                                        <input type="radio" id="isReassembly2" name="isReassembly" value="No" class="" formControlName="isReassembly" required>
                                        <label for="isReassembly2" class="label">No</label><br> 
                                    </div>
                                </div>
                                <app-field-error-display [displayError]="isFieldValid('isReassembly')" errorMsg="Reassembly is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <label for="isPacking" class="label required-field">Packing required?</label>
                        </div>
                        <div class="col-lg-8 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('isPacking')">
                                <div class="row">
                                    <div class="col-lg-2 col-sm-6">
                                        <input type="radio" id="isPacking1" name="isPacking" value="Yes" class="" formControlName="isPacking" required>
                                        <label for="isPacking1" class="label">Yes</label><br>
                                    </div>
                                    <div class="col-lg-2 col-sm-6">
                                        <input type="radio" id="isPacking2" name="isPacking" value="No" class="" formControlName="isPacking" required>
                                        <label for="isPacking2" class="label">No</label><br> 
                                    </div>
                                </div>
                                <app-field-error-display [displayError]="isFieldValid('isPacking')" errorMsg="Packing is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <label for="isGarage" class="label required-field">Garage/Shed/Garden Items?</label>
                        </div>
                        <div class="col-lg-8 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('isGarage')">
                                <div class="row">
                                    <div class="col-lg-2 col-sm-6">
                                        <input type="radio" id="isGarage1" name="isGarage" value="Yes" class="" formControlName="isGarage" required>
                                        <label for="isGarage1" class="label">Yes</label><br>
                                    </div>
                                    <div class="col-lg-2 col-sm-6">
                                        <input type="radio" id="isGarage2" name="isGarage" value="No" class="" formControlName="isGarage" required>
                                        <label for="isGarage2" class="label">No</label><br> 
                                    </div>
                                </div>
                                <app-field-error-display [displayError]="isFieldValid('isGarage')" errorMsg="Garage/Shed/Garden is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <label for="removalType1" class="label required-field">Removal type</label>
                        </div>
                        <div class="col-lg-8 col-sm-6">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6" [ngClass]="displayFieldCss('removalType1')">
                                        <select id="removalType1" name="removalType1"  formControlName="removalType1" class="form-control" required>
                                            <option value="">Please Select</option>
                                            <option [ngValue]="c" *ngFor="let c of removalType1List">{{c}}</option>
                                        </select>
                                        <app-field-error-display [displayError]="isFieldValid('removalType1')" errorMsg="Removal type 1 is required"></app-field-error-display>
                                    </div>
                                    <div class="col-lg-6 col-sm-6" [ngClass]="displayFieldCss('removalType2')">
                                        <select id="removalType2" name="removalType2"  formControlName="removalType2" class="form-control" required>
                                            <option value="">Please Select</option>
                                            <option [ngValue]="c" *ngFor="let c of removalType2List">{{c}}</option>
                                        </select> 
                                        <app-field-error-display [displayError]="isFieldValid('removalType2')" errorMsg="Removal type 2 is required"></app-field-error-display>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="section-title-sub">
                        <h5>Property details</h5>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12">
                            <label class="label"><b>Moving from</b></label>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fHouse" class="label required-field">House No</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('fHouse')">
                                <input type="text" id="fHouse" class="form-control" formControlName="fHouse" required>
                                <app-field-error-display [displayError]="isFieldValid('fHouse')" errorMsg="House No is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fAddress" class="label">Address</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="fAddress" class="form-control" formControlName="fAddress">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fTown" class="label">Town/city</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="fTown" class="form-control" formControlName="fTown">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fCounty" class="label">County</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="fCounty" class="form-control" formControlName="fCounty">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fPostcode" class="label">Postcode</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="fPostcode" class="form-control" formControlName="fPostcode">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fPropertyType" class="label required-field">Property type</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('fPropertyType')">
                                <select id="fPropertyType" name="fPropertyType"  formControlName="fPropertyType" class="form-control" required>
                                    <option value="">Please Select</option>
                                    <option [ngValue]="c" *ngFor="let c of fPropertyTypeList">{{c}}</option>
                                </select>
                                <app-field-error-display [displayError]="isFieldValid('fPropertyType')" errorMsg="Property type is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fOnFloor" class="label">On floor no</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="fOnFloor" class="form-control" formControlName="fOnFloor">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fBedrooms" class="label">No of rooms</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="fBedrooms" class="form-control" formControlName="fBedrooms">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="fIsLift" class="label">Lift available?</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="checkbox" id="fIsLift" formControlName="fIsLift" class="check-box">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-2 col-sm-6">
                            <label class="label"><b>Moving to</b></label>
                        </div>
                        <div class="col-lg-10 col-sm-6">
                            <label><input type="checkbox" id="tIsStorage" formControlName="tIsStorage" class="check-box"> &nbsp;Storage</label>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tHouse" class="label required-field">House No</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('tHouse')">
                                <input type="text" id="tHouse" class="form-control" formControlName="tHouse" required>
                                <app-field-error-display [displayError]="isFieldValid('tHouse')" errorMsg="House No is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tAddress" class="label">Address</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="tAddress" class="form-control" formControlName="tAddress">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tTown" class="label">Town/city</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="tTown" class="form-control" formControlName="tTown">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tCounty" class="label">County</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="tCounty" class="form-control" formControlName="tCounty">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tPostcode" class="label">Postcode</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="tPostcode" class="form-control" formControlName="tPostcode">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tPropertyType" class="label required-field">Property type</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group" [ngClass]="displayFieldCss('tPropertyType')">
                                <select id="tPropertyType" name="tPropertyType"  formControlName="tPropertyType" class="form-control" required>
                                    <option value="">Please Select</option>
                                    <option [ngValue]="c" *ngFor="let c of fPropertyTypeList">{{c}}</option>
                                </select>
                                <app-field-error-display [displayError]="isFieldValid('tPropertyType')" errorMsg="Property type is required"></app-field-error-display>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tOnFloor" class="label">On floor no</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="tOnFloor" class="form-control" formControlName="tOnFloor">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tBedrooms" class="label">No of rooms</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="text" id="tBedrooms" class="form-control" formControlName="tBedrooms">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                            <label for="tIsLift" class="label">Lift available?</label>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <input type="checkbox" id="tIsLift" formControlName="tIsLift" class="check-box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="section-title-sub">
                        <h5>Additional info</h5>
                    </div>
                    <div class="form-group">
                        <textarea name="additionalInfo" class="form-control" id="additionalInfo" cols="30" rows="8" formControlName="additionalInfo"></textarea>
                    </div>
                </div>
            </div>
            <div class="row"> 
                <div class="col-lg-12 col-md-12 text-center">
                    <button type="submit" class="default-btn">Submit</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Quotation Area End -->
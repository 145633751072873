<!-- Inner Banner -->
<div class="inner-banner inner-bg3">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Storage</h3>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>Storage</li>
          </ul>
      </div>
  </div>
</div>
<!-- Inner Banner End -->


<!-- Services Details -->
<div class="services-details pt-100 pb-70">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-12">
              <div class="services-details-content">
                  <img src="assets/img/services/services-details-5.jpg" alt="Images">

                  <div class="row">
                      <div class="col-lg-12 col-md-12">
                          <div class="services-details-card">
                              <h3>Storage</h3>
                              <p>Ready Steady Movers Offer A Storage Solution To Suit Your Needs. We Use Local Companies In Your Area With Affordable Prices. We will collect your items from your home, take them to a storage close to your property or new location and store them for when you are ready. </p>
                              <p>Once you are ready for your items, we will then collect them from your storage unit and deliver them right to your door.</p>
                              <br/>
                              <div class="service-advantage">
                                <div class="advantage-item">
                                  <ul>
                                      <li><i class='bx bx-check'></i> Local to you</li>
                                      <li><i class='bx bx-check'></i> Access your storage locally</li>
                                      <li><i class='bx bx-check'></i> Storage company will be the closest and cheapest to your location</li>
                                      <li><i class='bx bx-check'></i> We will find the storage for you</li>
                                      <li><i class='bx bx-check'></i> We will take your items to the storage</li>
                                      <li><i class='bx bx-check'></i> We will then re-deliver to you once you are ready</li>
                                  </ul>
                              </div>
                            </div>
                            <p>We will always aim to find the best possible deals for you.</p>
                          </div>
                      </div>
                  </div>

                 
              </div>
          </div>

          <div class="col-lg-4 col-md-12">
              <app-services-category></app-services-category>
          </div>
      </div>
  </div>
</div>
<!-- Services Details End -->



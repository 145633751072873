<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/footer-logo.png" alt="Images"></a>
                    </div>
                    <p>A friendly, reliable and professional moving service tailored to your individiual needs. Striving to make every move a great experience.</p>
                    <ul class="social-link">
                        <li><a href="https://linktr.ee/ReadySteadyMovers" target="_blank"><span class="iconify" data-icon="simple-icons:linktree" data-inline="false"></span></a></li>
                        <li><a href="https://g.co/kgs/rQPXd2" target="_blank"><i class='bx bxl-google'></i></a></li>
                        <li><a href="https://www.facebook.com/readysteadymovers" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/readysteadymovers/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://twitter.com/RSteadyMovers" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/get-quote">Quotation</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-list-two">
                        <li>
                            <i class='bx bxs-phone'></i>
                            <a href="tel:+44 1224 483098"> +44 1224 483098</a>
                        </li>
                        <li>
                            <i class='bx bxs-mobile'></i>
                            <a href="tel:+44 7869 680196"> +44 7869 680196</a>
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:sales@readysteadymovers.co.uk"> sales@readysteadymovers.co.uk</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>Copyright <fa-icon [icon]="['fas', 'copyright']"></fa-icon> {{ today | date:'y'}}  Ready Steady Movers. All Rights Reserved by <a href="javascript:void(0)"> Slick Technologies</a></p>
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->
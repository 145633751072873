<!-- Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img about-img-before">
                    <img src="assets/img/about/about-img1.jpg" alt="Images">

                    <div class="about-img-small">
                        <img src="assets/img/about/about-img2.jpg" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content about-content-max">
                    <span>About Us</span>
                    <h2>Always On Hand To Provide You <b>Friendly</b> Advice</h2>
                    <h3>Trusted And Effective Movers Service Provider </h3>
                    <p>Testament to the quality of our services is the fact that a great deal of our business comes from repeat customers and word of mouth. Once you’ve used our service we’re the company that you’ll return to, time and time again for your removal needs.</p>
                    <p>Equipped with correct equipment, vans, our uniform and highly competent workforce has all that’s needed for a smooth, on-time move without any hiccups.</p>
                    <p>Ready Steady Movers know that everyone of our customers has different needs, which is why no two moves are ever the same. Whatever your situation, we offer a wide solution of moving services, which means we can personalise the entire move to suit your needs.</p>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>Affordable</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>Insured</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>Reliable</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-polygon-shape">
        <img src="assets/img/shape/shape10.png" alt="Images">
    </div>
</div>
<!-- About Area End -->

<!-- Choose Area -->
<div class="choose-area-two choose-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <span>Why Choose Us</span>
                        <h2>Trusted Movers Company To Give You Right Guideline</h2>
                        <p>We aim to be your first choice for a local removals company in Aberdeen & Aberdeenshire; we can take care of the moving or removal, from basic loading and transport, to complete preparation including packing, furniture dismantling and assembly.</p>
                        <a routerLink="/get-quote" class="default-btn">Get A Quote</a>
                    </div>

                    <div class="row border-top">
                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-sort"></i>
                                <div class="content">
                                    <h3>Affordable</h3>
                                    <p>We love helping people move, on time and on budget. Fixed pricing with no hidden fees. Let's get moving!</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>Insured</h3>
                                    <p>We are fully equiped and insured. We go the extra mile with a smile. Making your move a dream come true.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-analytics"></i>
                                <div class="content">
                                    <h3>Reliable</h3>
                                    <p>We build our reputation on great, reliable customer service and communication. Helping you have a great move.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-chess-pieces"></i>
                                <div class="content">
                                    <h3>Experienced Team</h3>
                                    <p>Our experienced team go the extra mile with a smile. Making your move a dream come true.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="choose-img-2">
                    <img src="assets/img/choose/choose-img4.jpg" alt="Images">

                    <div class="choose-dots">
                        <img src="assets/img/choose/choose-dots.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

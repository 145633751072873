import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBoxOpen, faBuilding, faCopyright, faHome, faLocationArrow, faPeopleCarry, faPuzzlePiece, faTruckLoading } from '@fortawesome/free-solid-svg-icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FieldErrorDisplayComponent } from './components/common/field-error-display/field-error-display.component';
import { QuoteComponent } from './components/pages/quote/quote.component';
import { MovingHomeComponent } from './components/pages/moving-home/moving-home.component';
import { ServicesCategoryComponent } from './components/pages/services-category/services-category.component';
import { PackingComponent } from './components/pages/packing/packing.component';
import { OfficeMovesComponent } from './components/pages/office-moves/office-moves.component';
import { DismantleComponent } from './components/pages/dismantle/dismantle.component';
import { StorageComponent } from './components/pages/storage/storage.component';
import { ManVanComponent } from './components/pages/man-van/man-van.component';
import { NationalMovesComponent } from './components/pages/national-moves/national-moves.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    ComingSoonComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ErrorComponent,
    AboutComponent,
    MovingHomeComponent,
    PackingComponent,
    OfficeMovesComponent,
    DismantleComponent,
    StorageComponent,
    ManVanComponent,
    NationalMovesComponent,
    ContactComponent,
    QuoteComponent,
    FieldErrorDisplayComponent,
    ServicesCategoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule 
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private library: FaIconLibrary) {
    library.addIcons(faCopyright, faHome, faTruckLoading, faBuilding, faPuzzlePiece, faBoxOpen, faPeopleCarry, faLocationArrow);
  }
}


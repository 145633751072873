import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DismantleComponent } from './components/pages/dismantle/dismantle.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomeComponent } from './components/pages/home/home.component';
import { ManVanComponent } from './components/pages/man-van/man-van.component';
import { MovingHomeComponent } from './components/pages/moving-home/moving-home.component';
import { NationalMovesComponent } from './components/pages/national-moves/national-moves.component';
import { OfficeMovesComponent } from './components/pages/office-moves/office-moves.component';
import { PackingComponent } from './components/pages/packing/packing.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { QuoteComponent } from './components/pages/quote/quote.component';
import { StorageComponent } from './components/pages/storage/storage.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'moving-home', component: MovingHomeComponent},
    {path: 'packing', component: PackingComponent},
    {path: 'office-moves', component: OfficeMovesComponent},
    {path: 'dismantle', component: DismantleComponent},
    {path: 'storage', component: StorageComponent},
    {path: 'man-van', component: ManVanComponent},
    {path: 'national-moves', component: NationalMovesComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'get-quote', component: QuoteComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: '**', component: ErrorComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
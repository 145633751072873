import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dismantle',
  templateUrl: './dismantle.component.html',
  styleUrls: ['./dismantle.component.scss']
})
export class DismantleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-moving-home',
  templateUrl: './moving-home.component.html',
  styleUrls: ['./moving-home.component.scss']
})
export class MovingHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  salutationList = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Sir', 'Mr & Mrs'];
  removalType1List = ['Full removal', 'Part removal', 'Large item only'];
  removalType2List = ['Chain free', 'In Chain', 'Rental to rental', 'Rental to purchase', 'Rental to storage', 'Owned property to storage'];
  fPropertyTypeList = ['Studio', 'Maisonette', 'Flat', 'Bungalow', 'House', 'Storage', 'Office'];
  
  private formSumitAttempt: boolean;
  baseURL: any;
  formData: any = new FormData();

  constructor(private fb: FormBuilder,
    private http: HttpClient) { }

  quotationForm = this.fb.group({
    salutation: new FormControl(''),
    firstName: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
    companyName: new FormControl(''),
    moveDate: new FormControl('', [Validators.required]),
    landline: new FormControl('', [Validators.required]),
    extension: new FormControl(''),
    mobileNumber: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    isReassembly: new FormControl('', [Validators.required]),
    isPacking: new FormControl('', [Validators.required]),
    isGarage: new FormControl('', [Validators.required]),
    removalType1: new FormControl('', [Validators.required]),
    removalType2: new FormControl('', [Validators.required]),
    fHouse: new FormControl('', [Validators.required]),
    fAddress: new FormControl(''),
    fTown: new FormControl(''),
    fCounty: new FormControl(''),
    fPostcode: new FormControl(''),
    fPropertyType: new FormControl('', [Validators.required]),
    fOnFloor: new FormControl(''),
    fBedrooms: new FormControl(''),
    fIsLift: new FormControl('', [Validators.required]),
    tIsStorage: new FormControl(''),
    tHouse: new FormControl('', [Validators.required]),
    tAddress: new FormControl(''),
    tTown: new FormControl(''),
    tCounty: new FormControl(''),
    tPostcode: new FormControl(''),
    tPropertyType: new FormControl('', [Validators.required]),
    tOnFloor: new FormControl(''),
    tBedrooms: new FormControl(''),
    tIsLift: new FormControl('', [Validators.required]),
    additionalInfo: new FormControl(''),
  });

  ngOnInit() {}

  isFieldValid(field: string) {
    return (
      (!this.quotationForm.get(field).valid && this.quotationForm.get(field).touched) ||
      (this.quotationForm.get(field).untouched && this.formSumitAttempt)
    );
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  valueAppend(){
    this.formData.append("salutation", this.quotationForm.get('salutation').value);
    this.formData.append("firstName", this.quotationForm.get('firstName').value);
    this.formData.append("surname", this.quotationForm.get('surname').value);
    this.formData.append("companyName", this.quotationForm.get('companyName').value);
    this.formData.append("moveDate", this.quotationForm.get('moveDate').value);
    this.formData.append("landline", this.quotationForm.get('landline').value);
    this.formData.append("extension", this.quotationForm.get('extension').value);
    this.formData.append("mobileNumber", this.quotationForm.get('mobileNumber').value);
    this.formData.append("email", this.quotationForm.get('email').value);
    this.formData.append("isReassembly", this.quotationForm.get('isReassembly').value);
    this.formData.append("isPacking", this.quotationForm.get('isPacking').value);
    this.formData.append("isGarage", this.quotationForm.get('isGarage').value);
    this.formData.append("removalType1", this.quotationForm.get('removalType1').value);
    this.formData.append("removalType2", this.quotationForm.get('removalType2').value);
    this.formData.append("fHouse", this.quotationForm.get('fHouse').value);
    this.formData.append("fAddress", this.quotationForm.get('fAddress').value);
    this.formData.append("fTown", this.quotationForm.get('fTown').value);
    this.formData.append("fCounty", this.quotationForm.get('fCounty').value);
    this.formData.append("fPostcode", this.quotationForm.get('fPostcode').value);
    this.formData.append("fPropertyType", this.quotationForm.get('fPropertyType').value);
    this.formData.append("fOnFloor", this.quotationForm.get('fOnFloor').value);
    this.formData.append("fBedrooms", this.quotationForm.get('fBedrooms').value);
    this.formData.append("fIsLift", this.quotationForm.get('fIsLift').value);
    this.formData.append("tIsStorage", this.quotationForm.get('tIsStorage').value);
    this.formData.append("tHouse", this.quotationForm.get('tHouse').value);
    this.formData.append("tAddress", this.quotationForm.get('tAddress').value);
    this.formData.append("tTown", this.quotationForm.get('tTown').value);
    this.formData.append("tCounty", this.quotationForm.get('tCounty').value);
    this.formData.append("tPostcode", this.quotationForm.get('tPostcode').value);
    this.formData.append("tPropertyType", this.quotationForm.get('tPropertyType').value);
    this.formData.append("tOnFloor", this.quotationForm.get('tOnFloor').value);
    this.formData.append("tBedrooms", this.quotationForm.get('tBedrooms').value);
    this.formData.append("tIsLift", this.quotationForm.get('tIsLift').value);
    this.formData.append("additionalInfo", this.quotationForm.get('additionalInfo').value);
  }

  onSubmit(){
    this.formSumitAttempt = true;
    if (this.quotationForm.valid) {
      this.valueAppend();
      this.baseURL =  "https://readysteadymovers.co.uk/form/quotation.php";
      this.http.post(this.baseURL,this.formData)
      .subscribe(
        response => {
          window.location.reload();
          console.log("Res provider :", response)
        },
        error => console.log("err : ", error)
      );
    }
  }
}

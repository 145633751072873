<!-- Banner Area -->
<div class="banner-area-three banner-bg">
  <div class="container">
      <div class="banner-content banner-content-three">
          <span>Remove the stress with</span>
          <h1>Ready Steady Movers</h1>
          <p>A friendly, reliable and professional moving service tailored to your individiual needs. Striving to make every move a great experience.</p>
          <div class="banner-btn">
              <a routerLink="/contact" class="contact-btn">Contact Us</a>
              <a routerLink="/get-quote" class="get-btn">Get A Quote</a>
          </div>
      </div>
  </div>
</div>
<!-- Banner Area End -->

<!-- Status Area -->
<div class="status-area">
  <div class="container status-bg">
      <div class="row">
          <div class="col-lg-4 col-md-6">
              <div class="status-card">
                  <h3>Affordable</h3>
                  <p>We love helping people move, on time and on budget. Fixed pricing with no hidden fees. Let's get moving!</p>
                  <i class='flaticon-magnifying-glass'></i>
                  <div class="status-bottom-1"></div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="status-card">
                  <h3>Insured</h3>
                  <p>We are fully equiped and insured. We go the extra mile with a smile. Making your move a dream come true.</p>
                  <i class='flaticon-bank'></i>
                  <div class="status-bottom-2"></div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
              <div class="status-card">
                  <h3>Reliable</h3>
                  <p>We build our reputation on great, reliable customer service and communication.</p>
                  <i class='flaticon-settings'></i>
                  <div class="status-bottom-3"></div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Status Area End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-md-12">
              <div class="section-title">
                  <span>Our Services</span>
                  <h2>Our Best Movers Services Make You Reach Your Goal</h2>
              </div>
          </div>

          <div class="col-lg-6 col-md-12">
              <div class="service-text">
                  <p>Moving house, office and commercial should be an enjoyable experience – so let us be your first choice of Aberdeen’s & Aberdeenshire removals company, the first choice when moving.</p>
              </div>
          </div>
      </div>

      <div class="row pb-20">
          <div class="col-lg-4 col-md-6">
              <div class="services-card services-card-bg">
                  <a routerLink="/moving-home" class="services-icon"><fa-icon [icon]="['fas', 'home']"></fa-icon></a>
                  <h3><a routerLink="/moving-home">Moving Home</a></h3>
                  <p>Moving home couldn’t be simpler, call us, and relax, don’t break your back, let the professionals take care of it.</p>
                  <a routerLink="/moving-home" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                  <div class="services-card-bottom"></div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="services-card services-card-bg">
                  <a routerLink="/packing" class="services-icon"><fa-icon [icon]="['fas', 'truck-loading']"></fa-icon></a>
                  <h3><a routerLink="/packing">Packing</a></h3>
                  <p>Packing services mean you don’t have to lift a finger and our crew will take the full stress out of the move for you.</p>
                  <a routerLink="/packing" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                  <div class="services-card-bottom"></div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="services-card services-card-bg">
                  <a routerLink="/office-moves" class="services-icon"><fa-icon [icon]="['fas', 'building']"></fa-icon></a>
                  <h3><a routerLink="/office-moves">Office Moves</a></h3>
                  <p>Our services are ideal for offices looking to move with minimal impact to your day to day operations.</p>
                  <a routerLink="/office-moves" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                  <div class="services-card-bottom"></div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="services-card services-card-bg">
                  <a routerLink="/dismantle" class="services-icon"><fa-icon [icon]="['fas', 'puzzle-piece']"></fa-icon></a>
                  <h3><a routerLink="/dismantle">Dismantle</a></h3>
                  <p>We provide Dismantle And Re-Assemble Services For All Home Moves At Competitive Prices.</p>
                  <a routerLink="/dismantle" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                  <div class="services-card-bottom"></div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="services-card services-card-bg">
                  <a routerLink="/storage" class="services-icon"><fa-icon [icon]="['fas', 'box-open']"></fa-icon></a>
                  <h3><a routerLink="/storage">Storage</a></h3>
                  <p>We offer a storage solution to suit your needs. We use local companies in your area with affordable prices.</p>
                  <a routerLink="/storage" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                  <div class="services-card-bottom"></div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="services-card services-card-bg">
                  <a routerLink="/man-van" class="services-icon"><fa-icon [icon]="['fas', 'people-carry']"></fa-icon></a>
                  <h3><a routerLink="/man-van">Man & Van Services</a></h3>
                  <p>Our man & van services are no different. From moves, to deliveries or store collections – we can help.</p>
                  <a routerLink="/man-van" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                  <div class="services-card-bottom"></div>
              </div>
          </div>
      </div>
      
  </div>

  <div class="service-shape">
      <div class="shape1"><img src="assets/img/shape/shape6.png" alt="Images"></div>
      <div class="shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
      <div class="shape3"><img src="assets/img/shape/shape8.png" alt="Images"></div>
  </div>
</div>
<!-- Services Area End -->


<!-- About Area -->
<div class="about-area pt-100 pb-70" id="about">
  <div class="container-fluid">
      <div class="row">
          <div class="col-lg-6 col-md-12 pl-0">
              <div class="about-img-3">
                  <img src="assets/img/about/about-img5.jpg" alt="Images">
              </div>
          </div>

          <div class="col-lg-6 col-md-12">
              <div class="about-content about-content-max">
                  <span>About Us</span>
                  <h2>Always On Hand To Provide You With <b>Friendly</b> Advice</h2>
                  <h3>Trusted And Effective Movers Service Provider </h3>
                  <p>Testament to the quality of our services is the fact that a great deal of our business comes from repeat customers and word of mouth. Once you’ve used our service we’re the company that you’ll return to, time and time again for your removal needs.</p>
                  <p>Equipped with correct equipment, vans, our uniform and highly competent workforce has all that’s needed for a smooth, on-time move without any hiccups.</p>
                  <p>Ready Steady Movers know that everyone of our customers has different needs, which is why no two moves are ever the same. Whatever your situation, we offer a wide solution of moving services, which means we can personalise the entire move to suit your needs.</p>

                  <div class="row">
                      <div class="col-lg-4 col-md-4 pr-0">
                          <div class="about-counter">
                              <i class="flaticon-medal"></i>
                              <div class="content">
                                  <h3>Affordable</h3>
                              </div>
                          </div>
                      </div>

                      <div class="col-lg-4 col-md-4 pr-0">
                          <div class="about-counter">
                              <i class="flaticon-team"></i>
                              <div class="content">
                                  <h3>Insured</h3>
                              </div>
                          </div>
                      </div>

                      <div class="col-lg-4 col-md-4 pr-0">
                          <div class="about-counter">
                              <i class="flaticon-briefcase"></i>
                              <div class="content">
                                  <h3>Reliable</h3>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- About Area End -->

<!-- Choose Area -->
<div class="choose-area-two pt-100 pb-70">
  <div class="container-fluid">
      <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 pl-0">
              <div class="choose-content choose-content-bg">
                  <div class="choose-max">
                      <div class="section-title">
                          <span>Why Choose Us</span>
                          <h2>Trusted Movers Company To Give You Right Guideline</h2>
                          <p>We aim to be your first choice for a local removals company in Aberdeen & Aberdeenshire; we can take care of the moving or removal, from basic loading and transport, to complete preparation including packing, furniture dismantling and assembly. </p>
                          <a routerLink="/get-quote" class="default-btn">Get A Quote</a>
                      </div>
                      
                      <div class="row border-top">
                          <div class="col-lg-6 col-sm-6">
                              <div class="choose-content-list choose-content-list-color">
                                  <i class="flaticon-medal"></i>
                                  <div class="content">
                                      <h3>Affordable</h3>
                                      <p>We love helping people move, on time and on budget. Fixed pricing with no hidden fees. Let's get moving!</p>
                                  </div>
                              </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                              <div class="choose-content-list choose-content-list-color">
                                  <i class="flaticon-team"></i>
                                  <div class="content">
                                      <h3>Insured</h3>
                                      <p>We are fully equiped and insured. We go the extra mile with a smile. Making your move a dream come true.</p>
                                  </div>
                              </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                              <div class="choose-content-list choose-content-list-color">
                                  <i class="flaticon-briefcase"></i>
                                  <div class="content">
                                      <h3>Reliable</h3>
                                      <p>We build our reputation on great, reliable customer service and communication. Helping you have a great move.</p>
                                  </div>
                              </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                              <div class="choose-content-list choose-content-list-color">
                                  <i class="flaticon-chess-pieces"></i>
                                  <div class="content">
                                      <h3>Experienced Team</h3>
                                      <p>Our experienced team go the extra mile with a smile. Making your move a dream come true.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-5 col-md-12 pr-0">
              <div class="choose-img-3">
                  <img src="assets/img/choose/choose-img5.jpg" alt="Images">
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Choose Area End -->

<!-- Client Area -->
<!-- <div class="client-area-two ptb-100">
  <div class="container">
      <div class="section-title text-center">
          <span>Clients Review</span>
          <h2>Our Lovely Clients That Choose Help From Our Company</h2>
      </div>

      <div class="client-slider owl-carousel owl-theme pt-45">
          <div class="client-card">
              <h3>Masood Smith</h3>
              <span>CEO Of Divine Apps</span>
              <ul>
                  <li>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                  </li>
              </ul>
              <p>We used Ready Steady Movers to move our office. The guys were on the ball - carefull with all of our stuff and really helpful in taking some extras that weren't planned. Really efficient also despite having to take an awkward L-shape couch up 12 flights of stairs. No complaints or issue- w'd definitely use them again.</p>
              <i class="flaticon-quote client-card-quote"></i>
          </div>

          <div class="client-card">
              <h3>James Nilson</h3>
              <span>Manager Of ELEMECHS</span>
              <ul>
                  <li>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                  </li>
              </ul>
              <p>Excellent service - on time, fast, very careful with moving large bulky items, friendly and professional staff, reasonable rates.</p>
              <i class="flaticon-quote client-card-quote"></i>
          </div>

          <div class="client-card">
              <h3>Sinthy Alina</h3>
              <span>CEO Of Probase Pvt Ltd</span>
              <ul>
                  <li>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bx-star'></i>
                  </li>
              </ul>
              <p>We had a great experience with Ready Steady Movers in Feburary 2021. Fast, comparatively well-priced, helpful, competent and fully covered and sanitized due to Covid-19 pandemic. We have two removalists, this last move. Will probably hire a third man, next time.</p>
              <i class="flaticon-quote client-card-quote"></i>
          </div>

          <div class="client-card">
              <h3>M Zohaib Tariq</h3>
              <span>CEO Of Slick Technologies</span>
              <ul>
                  <li>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                      <i class='bx bxs-star'></i>
                  </li>
              </ul>
              <p>Professional from the moment you contact them till the very last item is delivered. Strongly recommend them!</p>
              <i class="flaticon-quote client-card-quote"></i>
          </div>
      </div>
  </div>
</div> -->
<!-- Client Area End -->

<!-- Leader Area -->
<div class="leader-area">
  <div class="container">
      <div class="leader-content">
          <div class="section-title text-center">
              <h2>Top Removalists Company in Aberdeen & Aberdeenshire</h2>
              <p>When you book with Ready Steady Movers, you’ve got one less task to worry about. That’s why our customers keep coming back time after time.</p>
          </div>
      </div>
  </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
  <div class="container">
      <div class="contact-area-bg">
          <div class="row">
              <div class="col-lg-6 col-md-12">
                  <div class="contact-form">
                      <div class="section-title text-center">
                          <h2>Contact Us</h2>
                      </div>

                      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group" [ngClass]="displayFieldCss('name')">
                                    <input type="text" name="name" id="name" class="form-control" formControlName="name" required placeholder="Name">
                                    <app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Name is required"></app-field-error-display>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group" [ngClass]="displayFieldCss('email')">
                                    <input type="email" name="email" id="email" class="form-control" formControlName="email" required placeholder="Email">
                                    <app-field-error-display [displayError]="isFieldValid('email')" errorMsg="Email is required"></app-field-error-display>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group" [ngClass]="displayFieldCss('phone_number')">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" formControlName="phone_number" placeholder="Phone">
                                    <app-field-error-display [displayError]="isFieldValid('phone_number')" errorMsg="Phone is required"></app-field-error-display>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group" [ngClass]="displayFieldCss('subject')">
                                    <input type="text" name="subject" id="subject" class="form-control" formControlName="subject" required placeholder="Your Subject">
                                    <app-field-error-display [displayError]="isFieldValid('subject')" errorMsg="Subject is required"></app-field-error-display>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group" [ngClass]="displayFieldCss('message')">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8" formControlName="message" required placeholder="Your Message"></textarea>
                                    <app-field-error-display [displayError]="isFieldValid('message')" errorMsg="Message is required"></app-field-error-display>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" class="default-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Contact Area End -->
<!-- Start Coming Soon Area -->
<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-content">
                    <h1>Ready Steady Movers</h1>
                    <h3>Coming Soon</h3>
                    <p>A friendly, reliable and professional moving service tailored to your individual needs. Striving to make every move a great experience. We aim to be your first choice for a local removals company in Aberdeen & Aberdeenshire.</p>

                    <div id="timer">
                        <div id="days">{{days}} <span>Days</span></div>
                        <div id="hours">{{hours}} <span>Hours</span></div>
                        <div id="minutes">{{minutes}} <span>Minutes</span></div>
                        <div id="seconds">{{seconds}} <span>Seconds</span></div>
                    </div>

                    <!-- <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL">
                        <button type="submit" class="default-btn">Notify Me</button>
                    </form> -->

                    <ul class="header-content-right">
                        <li><a href="https://www.facebook.com/readysteadymovers" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/RSteadyMovers" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Coming Soon Area -->
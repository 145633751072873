<!-- Inner Banner -->
<div class="inner-banner inner-bg3">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Man & Van Services</h3>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>Man & Van Services</li>
          </ul>
      </div>
  </div>
</div>
<!-- Inner Banner End -->


<!-- Services Details -->
<div class="services-details pt-100 pb-70">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-12">
              <div class="services-details-content">
                  <img src="assets/img/services/services-details-6.jpg" alt="Images">

                  <div class="row">
                      <div class="col-lg-12 col-md-12">
                          <div class="services-details-card">
                              <h3>Man & Van Services</h3>
                              <p>At Ready Steady Movers we pride ourselves on providing top quality services and our man and van services are no different. From smaller one-off moves, to deliveries or store collections – we can help. We use our jumbo Luton van that includes satellite navigation and is fully equipped with everything else we need for the moves such as blankets, straps, sack barrow, power tools. We are always dressed in full uniform, for professional appearance and safety.</p>
                              <h4>Store Collections</h4>
                              <p>Our M&V services include a store collection service for collecting furniture or large items from stores that are too big or bulky for you to transport yourself. Perfect if you have no way of getting your purchased items home – let us take on the heavy tasks, we are here to help. We can collect them from store and deliver them direct to you. This is great for places like IKEA as they don’t offer delivery services themselves. Ikea delivery can even include the building of flat-pack furniture. </p>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our delivery is not restricted to Ikea, it is great for any stores that have large bulky items that you can’t transport home yourself, or places that don’t offer delivery such as Gumtree, Facebook and eBay collections. What’s more, even when a store provides delivery, it is usually door to door – it does not put your new furniture together or put it into the place you want it to go.  </p>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We offer great prices for collection and delivery from most stores such as DFS sofas, Argos, B&Q and home base. So if you’re looking for man and van services in Aberdeen & Aberdeenshire then let’s talk, for a free no-obigation quote.  </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-12">
              <app-services-category></app-services-category>
          </div>
      </div>
  </div>
</div>
<!-- Services Details End -->



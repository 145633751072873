<div class="services-side-bar">
  <div class="services-bar-widget">
      <h3 class="title">Our Services</h3>
      <div class="services-bar-categories">
          <ul>
              <li>
                <i><fa-icon [icon]="['fas', 'home']"></fa-icon></i>
                  <a routerLink="/moving-home" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Moving Home </a>
              </li>
              <li>
                  <i><fa-icon [icon]="['fas', 'truck-loading']"></fa-icon></i>
                  <a routerLink="/packing" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Packing </a>
              </li>
              <li>
                  <i><fa-icon [icon]="['fas', 'building']"></fa-icon></i>
                  <a routerLink="/office-moves" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Office Moves </a>
              </li>
              <li>
                <i><fa-icon [icon]="['fas', 'puzzle-piece']"></fa-icon></i>
                <a routerLink="/dismantle" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dismantle </a>
              </li>
              <li>
                <i><fa-icon [icon]="['fas', 'box-open']"></fa-icon></i>
                <a routerLink="/storage" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Storage </a>
              </li>
              <li>
                <i><fa-icon [icon]="['fas', 'people-carry']"></fa-icon></i>
                <a routerLink="/man-van" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Man & Van Services </a>
              </li>
              <li>
                  <i><fa-icon [icon]="['fas', 'location-arrow']"></fa-icon></i>
                  <a routerLink="/national-moves" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">National Moves </a>
              </li>
          </ul>
      </div>
  </div>

  <div class="services-bar-widget">
      <div class="services-bar-contact">
          <i class='bx bx-phone-call'></i>
          <h3>Want to know more? Call Us for more Info</h3>
          <span><a href="tel:+44 1224 483098"> +44 1224 483098</a></span>
          <span><a href="tel:+44 7869 680196"> +44 7869 680196</a></span>
      </div>
  </div>
</div>
<!-- Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:sales@readysteadymovers.co.uk">sales@readysteadymovers.co.uk</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-phone'></i>
                                </div>
                                <a href="tel:+44 1224 483098"> +44 1224 483098</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-mobile'></i>
                                </div>
                                <a href="tel:+44 7869 680196"> +44 7869 680196</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li><a href="https://linktr.ee/ReadySteadyMovers" target="_blank"><span class="iconify" data-icon="simple-icons:linktree" data-inline="false"></span></a></li>
                            <li><a href="https://g.co/kgs/rQPXd2" target="_blank"><i class='bx bxl-google'></i></a></li>
                            <li><a href="https://www.facebook.com/readysteadymovers" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/readysteadymovers/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://twitter.com/RSteadyMovers" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        </ul>
                    </div>

                    <div class="language">
                        <ul>
                            <li>
                                <div class="banner-nav-btn">
                                    <a routerLink="/get-quote" class="contact-nav-btn">Get Quote</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Services <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/moving-home" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Moving Home</a></li>
                            <li class="nav-item"><a routerLink="/packing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Packing</a></li>
                            <li class="nav-item"><a routerLink="/office-moves" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Office Moves</a></li>
                            <li class="nav-item"><a routerLink="/dismantle" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dismantle</a></li>
                            <li class="nav-item"><a routerLink="/storage" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Storage</a></li>
                            <li class="nav-item"><a routerLink="/man-van" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Man & Van Services</a></li>
                            <li class="nav-item"><a routerLink="/national-moves" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">National Moves</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/get-quote" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Quotation</a></li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Home</a></li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">About Us</a></li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Services <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/moving-home" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Moving Home</a></li>
                            <li class="nav-item"><a routerLink="/packing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Packing</a></li>
                            <li class="nav-item"><a routerLink="/office-moves" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Office Moves</a></li>
                            <li class="nav-item"><a routerLink="/dismantle" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Dismantle</a></li>
                            <li class="nav-item"><a routerLink="/storage" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Storage</a></li>
                            <li class="nav-item"><a routerLink="/man-van" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Man & Van Services</a></li>
                            <li class="nav-item"><a routerLink="/national-moves" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">National Moves</a></li>
                        </ul>
                    </li>
                    
                    <li class="nav-item"><a routerLink="/get-quote" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Quotation</a></li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Contact</a></li>
                </ul>
                <!-- <div class="others-options d-flex align-items-center">
                    <div class="option-item">
                        <div class="menu-icon d-in-line">
                            <span class="burger-menu menu-icon-two"><i class='flaticon-menu' (click)="toggleClass()"></i></span>
                        </div>	
                    </div>
                </div> -->
            </div>
        </nav>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Sidebar Modal -->
<!-- <div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-header">
            <div class="sidebar-logo">
                <img src="assets/img/logo.png" alt="Image">
            </div>
            <span class="close-btn sidebar-modal-close-btn"><i class="bx bx-x"></i></span>
        </div>

        <div class="sidebar-about">
            <div class="title">
                <p>A friendly, reliable and professional moving service tailored to your individiual needs. Striving to make every move a great experience. We aim to be your first choice for a local removals company in Aberdeen.</p>
                <p>we can take care of the moving or removal, from basic loading and transport, to complete preparation including packing, furniture dismantling and assembly. We understand that everyone has different needs, whether its the size of the property, budget, a local removal or national removal.</p>
            </div>
        </div>

        <div class="contact-us">
            <h2>Contact Us</h2>

            <ul>
                <li>
                    <i class='bx bx-mail-send'></i>
                    <a href="mailto:sales@readysteadymovers.co.uk"> sales@readysteadymovers.co.uk</a>
                </li>
                <li>
                    <i class='bx bxs-phone'></i>
                    <a href="tel:+44 1224 483098"> +44 1224 483098</a>
                </li>
                <li>
                    <i class='bx bxs-mobile'></i>
                    <a href="tel:+44 7869 680196"> +44 7869 680196</a>
                </li>
            </ul>
        </div>
        
       

        <div class="sidebar-follow-us">
            <h2>Follow Us</h2>

            <ul class="social-wrap">
                <li><a href="https://www.facebook.com/readysteadymovers" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                <li><a href="https://twitter.com/RSteadyMovers" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                <li><a href="https://www.instagram.com/readysteadymovers/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                <li><a href="https://g.co/kgs/rQPXd2" target="_blank"><i class='bx bxl-google'></i></a></li>
                <li><a href="https://linktr.ee/ReadySteadyMovers" target="_blank"><span class="iconify linktree-ico" data-icon="simple-icons:linktree" data-inline="false"></span></a></li>
            </ul>
        </div>
    </div>
</div> -->
<!-- End Sidebar Modal -->
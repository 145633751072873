<!-- Inner Banner -->
<div class="inner-banner inner-bg3">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Office Moves</h3>
          <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>Office Moves</li>
          </ul>
      </div>
  </div>
</div>
<!-- Inner Banner End -->


<!-- Services Details -->
<div class="services-details pt-100 pb-70">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-12">
              <div class="services-details-content">
                  <img src="assets/img/services/services-details-3.jpg" alt="Images">

                  <div class="row">
                      <div class="col-lg-12 col-md-12">
                          <div class="services-details-card">
                              <h3>Office Moves</h3>
                              <p>Our office removals and relocation services are ideal for small and medium sized offices looking to move with minimal impact to your day to day operations. We can work evenings and weekends so that normal office hours will be affected as little as possible. Minimal downtime and disruption for your business is ideal. We can make your office move a dream come true.</p>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Office relocation can be a daunting task, we understand the importance of making sure the move is carried out with care and in a professional manner. You need to be back up and running as quick as you can, and we will help you achieve that with careful planning and execution. Our staff are trained, uniformed professionals and are always polite and friendly. </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-12">
              <app-services-category></app-services-category>
          </div>
      </div>
  </div>
</div>
<!-- Services Details End -->



import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  private formSumitAttempt: boolean;
  baseURL: any;
  formData: any = new FormData();

  constructor(private fb: FormBuilder,
    private http: HttpClient) { }

  contactForm = this.fb.group({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone_number: new FormControl('', [Validators.required]),
    subject: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
  });

  ngOnInit() {}

  isFieldValid(field: string) {
    return (
      (!this.contactForm.get(field).valid && this.contactForm.get(field).touched) ||
      (this.contactForm.get(field).untouched && this.formSumitAttempt)
    );
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  valueAppend(){
    this.formData.append("name", this.contactForm.get('name').value);
    this.formData.append("email", this.contactForm.get('email').value);
    this.formData.append("phone_number", this.contactForm.get('phone_number').value);
    this.formData.append("subject", this.contactForm.get('subject').value);
    this.formData.append("message", this.contactForm.get('message').value);
  }

  onSubmit(){
    this.formSumitAttempt = true;
    if (this.contactForm.valid) {
      this.valueAppend();
      this.baseURL =  "https://readysteadymovers.co.uk/form/contact.php";
      this.http.post(this.baseURL,this.formData)
      .subscribe(
        response => {
          window.location.reload();
          console.log("Res provider :", response)
        },
        error => console.log("err : ", error)
      );
    }
  }

}
